import React, { useState } from 'react';

function SquareInput({ value, onChange }) {
  const [showWarning, setShowWarning] = useState(false);

  const handleChange = (e) => {
    if (e.target.value.length >= 500) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
    onChange(e);
  };

  return (
    <div className="mt-4 flex flex-col">
      <textarea 
        className="w-full p-2 text-left border border-gray-300 rounded-xl resize-none"
        placeholder="Gostaria de enviar algum comentário sobre esse chamado?"
        value={value}
        onChange={handleChange}
        maxLength={500}
        rows={4}
        style={{ overflow: 'hidden' }}
      />
      {showWarning && (
        <p className="text-sm text-red-500 mt-2">
          Você atingiu a quantidade máxima de caracteres.
        </p>
      )}
    </div>
  );
}

export default SquareInput;
