import React, { useState, useEffect } from "react";
import { createRoot } from 'react-dom/client';
import Card from "../../components/card/Card";
import Dividers from "../../components/divider/Dividers";
import EmojiBox from "../../components/emoji-box/EmojiBox";
import ButtonCustom from "../../components/button/Button";
import SquareInput from "../../components/input/input.jsx";
import DialogCuston from "../../components/dialogs/Dialog.jsx";
import "./styles.css";
import { XMarkIcon, ExclamationTriangleIcon, CheckIcon } from '@heroicons/react/24/outline'

import {
  nameClient,
  dateClient,
  protocolClient,
  categoryService,
  subcategoryService,
  emailClient,
  grupOperator,
  idCcalled,
  serviceNoteOne,
  dateEnd,
} from "../../client/clientParameters.jsx";

function Home() {
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState({
    atendimento: null,
    sistema: null,
    solucao: null,
    tempo: null,
    comentario: "",
  });

  useEffect(() => {
    setFeedback((prev) => ({
      ...prev,
      atendimento: serviceNoteOne,
    }));
  }, []);

  const handleEmojiClick = (id, value) => {
    setFeedback((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const XIcon = XMarkIcon;
  const EsclamationIcon = ExclamationTriangleIcon;
  const Check = CheckIcon

  function showAlertAndRedirect() {
    const dialogContainer = document.createElement('div');
    document.body.appendChild(dialogContainer);
    const root = createRoot(dialogContainer);
  
    root.render(
      <DialogCuston 
        onClose={() => {
          root.unmount();
          document.body.removeChild(dialogContainer);
          window.location.href = "https://atendimento.rnp.br";
        }}
        title="Muito obrigado pela sua participação!"
        description="Seus feedbacks são essenciais para que possamos aprimorar continuamente nossos serviços. A equipe de qualidade avalia cada resposta com cuidado para garantir a melhor experiência possível para todos."
        image="https://media.tenor.com/I8zQVEOSo14AAAAC/ok.gif"
      />
    );
  }
  
  const handleChange = (event) => {
    setFeedback((prev) => ({
      ...prev,
      comentario: event.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const { atendimento, sistema, solucao, tempo, comentario } = feedback;

    if (!atendimento || !sistema || !solucao || !tempo) {
      setLoading(false);
      const dialogContainer = document.createElement('div');
      document.body.appendChild(dialogContainer);
      const root = createRoot(dialogContainer);
    
      root.render(
        <DialogCuston 
          onClose={() => {
            root.unmount();
            document.body.removeChild(dialogContainer);
          }}
          title="Campo Obrigatório Faltando"
          description="Por favor, preencha todos os campos requeridos antes de continuar."
          bg="bg-red-100"
          iconColor="text-red-600"
          icon={EsclamationIcon}
        />
      );
      return;
    }
    

    let dados = {
      protocolo: protocolClient,
      categoria: categoryService,
      subcategoria: subcategoryService,
      data: dateEnd,
      nome: nameClient,
      email: emailClient,
      grupo_operador: grupOperator,
      identificador: idCcalled,
      nota_atendimento: atendimento,
      nota_servico: sistema,
      nota_solucao: solucao,
      nota_tempo: tempo,
      comentario: comentario,
    };

    console.log(dados);
    fetch("https://satisfaction-survey.herokuapp.com/feedbacks/", {
      method: "POST",
      body: JSON.stringify(dados),
      headers: {
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_BACKEND_KEY,
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.status === 201) {
          return res.json();
        } else if (res.status === 409) {
          return new Promise((resolve) => {
            const dialogContainer = document.createElement('div');
            document.body.appendChild(dialogContainer);
            const root = createRoot(dialogContainer);
            
            root.render(
              <DialogCuston 
                onClose={() => {
                  root.unmount();
                  document.body.removeChild(dialogContainer);
                  resolve();
                  window.location.href = "https://atendimento.rnp.br";
                }}
                title="Você já respondeu essa pesquisa"
                description="A sua participação já foi registrada anteriormente. Obrigado por responder!"
                bg="bg-green-100"
                iconColor="text-green-600"
                icon={Check}
              />
            );
          });
        } else {
          throw new Error("Erro no envio do formulário");
        }
      })
      .then((dados) => {
        if(dados){
          showAlertAndRedirect();
        }
      })
      .catch((error) => {
        console.error("Erro ao enviar formulário:", error);
        setLoading(false);
        const dialogContainer = document.createElement('div');
        document.body.appendChild(dialogContainer);
        const root = createRoot(dialogContainer);
      
        root.render(
          <DialogCuston 
            onClose={() => {
              root.unmount();
              document.body.removeChild(dialogContainer);
            }}
            title="Ops! Algo inesperado aconteceu"
            description="Tivemos um problema técnico e não conseguimos processar sua solicitação no momento. Por favor, tente novamente mais tarde. Se o problema persistir, entre em contato com nosso suporte."
            bg="bg-red-100"
            iconColor="text-red-600"
            icon={XIcon}
          />
        );
      })
      .finally(() => {
        setLoading(false);
      });    
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <Card className="max-w-2xl">
          <div className="text-left">
            <h2 className="text-lg">
              Olá <b>{nameClient}</b>, essa pesquisa se refere ao chamado{" "}
              <b>{protocolClient}</b>, finalizado no dia <b>{dateClient}</b>.
              Contamos com a sua participação.
            </h2>
          </div>
          <h2>
            Qual a sua satisfação em relação ao <b>atendimento</b> deste
            chamado?
          </h2>
          <EmojiBox
            id="atendimento"
            initialValue={feedback.atendimento}
            onEmojiClick={handleEmojiClick}
          />
          <Dividers />
          <h2>
            Qual a sua satisfação em relação ao <b>serviço/sistema</b> que
            originou esse chamado?
          </h2>
          <EmojiBox id="sistema" onEmojiClick={handleEmojiClick} />
          <Dividers />
          <h2>
            Qual a sua satisfação em relação à <b>solução</b> aplicada?
          </h2>
          <EmojiBox id="solucao" onEmojiClick={handleEmojiClick} />
          <Dividers />
          <h2>
            Qual a sua satisfação em relação ao <b>tempo de resolução</b> da sua
            solicitação?
          </h2>
          <EmojiBox id="tempo" onEmojiClick={handleEmojiClick} />
          <SquareInput value={feedback.comentario} onChange={handleChange} />
          <div id="button" className="flex flex-row-reverse">
            <ButtonCustom isLoading={loading} type="submit">Enviar</ButtonCustom>
          </div>
        </Card>
      </form>
    </div>
  );
}

export default Home;
