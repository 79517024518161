import React, { useState, useEffect } from 'react';
import './style.css';

function EmojiBox({ id, onEmojiClick, initialValue }) {
  const [selectedValue, setSelectedValue] = useState(initialValue);

  useEffect(() => {
    if (initialValue) {
      setSelectedValue(initialValue);
    }
  }, [initialValue]);

  const sections = [
    { image: 'url("https://res.cloudinary.com/dijid8gky/image/upload/v1716775633/IMG-RNP/1_1_zzrjnj.png")', value: 1, text: 'Muito insatisfeito' },
    { image: 'url("https://res.cloudinary.com/dijid8gky/image/upload/v1716775634/IMG-RNP/2_1_sutnln.png")', value: 2, text: 'Insatisfeito' },
    { image: 'url("https://res.cloudinary.com/dijid8gky/image/upload/v1716775633/IMG-RNP/3_1_swtxry.png")', value: 3, text: 'Neutro' },
    { image: 'url("https://res.cloudinary.com/dijid8gky/image/upload/v1716775633/IMG-RNP/4_1_yofye9.png")', value: 4, text: 'Satisfeito' },
    { image: 'url("https://res.cloudinary.com/dijid8gky/image/upload/v1716775633/IMG-RNP/5_1_da4nsd.png")', value: 5, text: 'Muito Satisfeito' }
  ];

  const handleClick = (sectionValue) => {
    setSelectedValue(sectionValue);
    onEmojiClick(id, sectionValue);
  };

  return (
    <div className={`emoji-box flex flex-wrap justify-center w-full ${id}`} id={id}>
      {sections.map((section, index) => (
        <div key={index} className="emoji-section flex flex-col items-center mb-2 sm:mb-4 sm:mr-5 w-auto">
          <div
            onClick={() => handleClick(section.value)}
            style={{
              backgroundImage: section.image,
              filter: selectedValue === section.value ? 'none' : 'grayscale(100%)',
            }}
            className={`emoji-image ${selectedValue === section.value ? 'active' : ''}`}
            onMouseEnter={(e) => e.currentTarget.style.filter = 'none'}
            onMouseLeave={(e) => {
              if (selectedValue !== section.value) {
                e.currentTarget.style.filter = 'grayscale(100%)';
              }
            }}
          />
          <div className="text-center text-xs">
            {section.text}
          </div>
        </div>
      ))}
    </div>
  );
}

export default EmojiBox;
