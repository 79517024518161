
import DialogCustom from '../components/dialogs/Dialog'
import { createRoot } from 'react-dom/client';
import { XMarkIcon } from '@heroicons/react/24/outline'


const XIcon = XMarkIcon;

// Função para buscar parâmetros da URL
function getQueryParam(param) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
}

// Função para converter data em formato dd/mm/yyyy para yyyy-mm-dd
function formatDateString(dateStr) {
  const [day, month, year] = dateStr.split('/');
  return `${year}-${month}-${day}`;
}

// Função para calcular a diferença de dias entre duas datas
function getDaysDifference(dateStr) {
  const [day, month, year] = dateStr.split('/');
  const date = new Date(year, month - 1, day);
  const currentDate = new Date();
  const timeDifferenceInMilliseconds = currentDate - date;
  return Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24));
}
function showAlertAndRedirect() {
  const dialogContainer = document.createElement('div');
  document.body.appendChild(dialogContainer);
  const root = createRoot(dialogContainer);

  root.render(
    <DialogCustom 
      onClose={() => {
        root.unmount();
        document.body.removeChild(dialogContainer);
        window.location.href = "https://atendimento.rnp.br";
      }}
      title="Prazo expirado!"
      description="Parece que você passou do prazo para responder a pesquisa, contamos com a sua participação nas próximas oportunidades."
      bg="bg-red-100"
      iconColor="text-red-600"
      icon={XIcon}
    />
  );
}


export const dateClient = getQueryParam("data");
const timeDifferenceInDays = getDaysDifference(dateClient.split(' ')[0]);
if (timeDifferenceInDays >= 7) {
  showAlertAndRedirect(() => {
    window.location.href = "https://atendimento.rnp.br";
  });
}

export const nameClient = getQueryParam("nome");
export const protocolClient = getQueryParam("protocolo");
export const emailClient = getQueryParam("email");
export const categoryService = getQueryParam("categoria");
export const subcategoryService = getQueryParam("subcategoria");
export const grupOperator = getQueryParam("grupo_operador");
export const idCcalled = getQueryParam("idchamado");
export const serviceNoteOne = parseInt(getQueryParam("nota"), 10);

if (!protocolClient) {
  window.location.href = "https://atendimento.rnp.br/";
}

export const dateEnd = formatDateString(dateClient.split(' ')[0]);

export const urlBase = [
  `https://atendimento.rnp.br/solutions/five-star-review-1/process/${idCcalled}/${serviceNoteOne}/MBzCLyX9WFh_SY5I4jrFq5KRYvhAL6IhAcRZDKRLSSnSBUTFdkGYJWad03CxlDrjjKAQRoorJnSkuoYpdrBbxg`
];
