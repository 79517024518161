// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swal-button-custom {
  background-color: #000A8C !important; 
  color: #ffffff !important; 
  border: none; 
  padding: 10px 20px; 
  font-size: 16px; 
  cursor: pointer; 
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/styles.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;AACjB","sourcesContent":[".swal-button-custom {\n  background-color: #000A8C !important; \n  color: #ffffff !important; \n  border: none; \n  padding: 10px 20px; \n  font-size: 16px; \n  cursor: pointer; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
