import React, { useState } from "react";

export default function ButtonCustom({ children, type = "button", isLoading, onClick }) {
  return (
    <button
      type={type}
      className={`inline-flex mt-4 items-center gap-x-1.5 rounded-md bg-[#000A8C] px-3 py-2 text-sm font-semibold text-white shadow-sm ${
        isLoading ? 'bg-[#001EFF]' : 'hover:bg-[#001EFF]'
      } focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
      onClick={onClick}
      disabled={isLoading}
    >
      {isLoading ? (
        <>
          <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="white" strokeWidth="4"></circle>
            <path className="opacity-75" fill="white" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.313 1.343 6.315 3.514 8.485l2.486-3.194z"></path>
          </svg>
          Enviando...
        </>
      ) : (
        children
      )}
    </button>
  );
}
